<template>
  <div>
    <b-card>
      <div class="d-flex">
        <p class="my-auto mr-auto">
          <i :class="$route.meta.iconClass"></i>
          <span
            style="font-size: 22px; color: #7f7f7f"
            class="font-weight-light"
          >
            Fiche Objet Métier :
          </span>
          <span style="font-size: 25px" class="font-weight-bold">{{
            OBJET.name
          }}</span>
        </p>
        <div class="row mb-4">
          <div class="col-12">
            <modal-actions
              @close="
                $router.go(
                  $store.getters['routerHistory/fromCreation'] ? -2 : -1
                )
              "
            />
          </div>
        </div>
      </div>

      <div class="shdow">
        <div
          class="d-flex flex-wrap justify-content-between align-items-center"
        >
          <p class="h4 text-secondary my-auto mr-auto">
            Informations générales
          </p>

          <a
            v-if="(isAdmin || isSuper || isArchitect) && !editMode"
            class="text-secondary"
            style="font-size: 30px; color: #007bff !important"
            @click.prevent="editMode = !editMode"
            ><i class="mdi mdi-file-document-edit"></i
          ></a>
          <div
            v-else-if="isAdmin || isSuper || isArchitect"
            class="m-0 d-flex p-0"
          >
            <b-button
              style="min-width: 120px"
              type="submit"
              variant="secondary"
              pill
              @click="updateItem"
              :disabled="submitingForm"
            >
              <b-spinner
                v-if="submitingForm"
                small
                class="text-white"
                label="Loading..."
              ></b-spinner>
              Confirmer</b-button
            >
            <b-button
              @click="editMode = !editMode"
              pill
              style="background-color: #adb5bd; border-color: #adb5bd"
              >Annuler</b-button
            >
          </div>
        </div>
        <div class="col-12 p-0">
          <hr class="bg-secondary mb-3 mt-1" />
        </div>
        <div class="row" v-if="!editMode">
          <div class="col-12 col-md-6">
            <div class="my-2">
              <span class="font-weight-bold mr-3">Nom : </span>
              <span> {{ OBJET.name }} </span>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="my-2">
              <span class="font-weight-bold mr-3">sensible : </span>
              <b-badge
                v-if="
                  !OBJET.attributs ||
                  !OBJET.attributs.length ||
                  OBJET.attributs.every((attribut) => attribut.sensible == 0)
                "
                variant="success"
                >{{ 0 }}</b-badge
              >
              <b-badge v-else variant="danger">{{
                maxBy(OBJET.attributs, (attribut) => attribut.sensible).sensible
              }}</b-badge>
            </div>
          </div>

          <div class="col-12 col-md-6">
            <div class="my-2">
              <span class="font-weight-bold mr-3">Description : </span>
              <span> {{ OBJET.description }} </span>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <!-- {{ OBJET.attributs.find((attribut) => attribut.dcp == "Oui") }} -->
            <div class="my-2">
              <span class="font-weight-bold mr-3">DCP : </span>
              <b-badge
                v-if="
                  !OBJET.attributs ||
                  !OBJET.attributs.length ||
                  OBJET.attributs.every((attribut) => attribut.dcp === 'Non')
                "
                variant="success"
                >Non</b-badge
              >

              <b-badge v-else variant="danger">Oui</b-badge>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="my-2">
              <span class="font-weight-bold mr-3">Concept métier : </span>
              <span>
                {{ OBJET.concept ? OBJET.concept.name : "-" }}
              </span>
            </div>
          </div>
        </div>

        <div class="row" v-else>
          <!-- First Col -->
          <div class="col-12 col-md-6">
            <div class="row my-3 align-items-center">
              <div class="col-md-4">
                <label class="font-weight-bold">Nom:</label>
              </div>
              <div class="col-md-8">
                <b-form-input
                  type="text"
                  placeholder="Nom"
                  v-model="newObjet.name"
                  :class="{
                    'is-invalid':
                      $v.newObjet.name.$error && $v.newObjet.name.$dirty,
                  }"
                ></b-form-input>
                <!-- ERROR MESSAGES -->
                <span
                  style="font-size: x-small"
                  class="text-danger font-weight-normal"
                  v-if="$v.newObjet.name.$dirty"
                >
                  {{
                    !$v.newObjet.name.required
                      ? "champ obligatoire"
                      : !$v.newObjet.name.minLength
                      ? `Le champ doit contenir au moins ${$v.newObjet.name.$params.minLength.min} caractères.`
                      : ""
                  }}
                </span>
                <!--  -->
              </div>
            </div>

            <div class="row my-3 align-items-center">
              <div class="col-md-4">
                <label class="font-weight-bold">Description:</label>
              </div>
              <div class="col-md-8">
                <b-form-textarea
                  type="text"
                  placeholder="Description"
                  v-model="newObjet.description"
                ></b-form-textarea>
              </div>
            </div>

            <div class="row my-3 align-items-center">
              <div class="col-md-4">
                <label class="font-weight-bold">Concept:</label>
              </div>
              <div class="col-md-8" id="add-page">
                <div
                  class="font-weight-normal p-0 form-control"
                  style="padding: 0 !important; height: fit-content"
                  :class="{
                    'is-invalid':
                      $v.newObjet.concept.$error && $v.newObjet.concept.$dirty,
                  }"
                >
                  <v-select
                    label="text"
                    placeholder="Concept"
                    v-model="newObjet.concept"
                    :options="conceptsList"
                    appendToBody
                    :reduce="({ value }) => value"
                    :class="{
                      'is-invalid':
                        $v.newObjet.concept.$error &&
                        $v.newObjet.concept.$dirty,
                    }"
                  ></v-select>
                </div>
                <!-- ERROR MESSAGES -->
                <span
                  style="font-size: x-small"
                  class="text-danger font-weight-normal"
                  v-if="$v.newObjet.concept.$dirty"
                >
                  {{ !$v.newObjet.concept.required ? "champ obligatoire" : "" }}
                </span>
                <!--  -->
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="row my-3 align-items-center">
              <div class="col-md-4">
                <label class="font-weight-bold">DCP:</label>
              </div>
              <div class="col-md-8">
                <b-form-select
                  type="text"
                  placeholder="DCP"
                  v-model="newObjet.dcp"
                  disabled
                  :class="{
                    'is-invalid':
                      $v.newObjet.dcp.$error && $v.newObjet.dcp.$dirty,
                  }"
                  :options="['Oui', 'Non']"
                ></b-form-select>
                <!-- ERROR MESSAGES -->
                <span
                  style="font-size: x-small"
                  class="text-danger font-weight-normal"
                  v-if="$v.newObjet.dcp.$dirty"
                >
                  {{ !$v.newObjet.dcp.required ? "champ obligatoire" : "" }}
                </span>
                <!--  -->
              </div>
            </div>

            <div class="row my-3 align-items-center">
              <div class="col-md-4">
                <label class="font-weight-bold">Sensible:</label>
              </div>
              <div class="col-md-8">
                <b-form-select
                  type="text"
                  placeholder="Sensible"
                  v-model="newObjet.sensible"
                  disabled
                  :class="{
                    'is-invalid':
                      $v.newObjet.sensible.$error &&
                      $v.newObjet.sensible.$dirty,
                  }"
                  :options="[0, 1, 2, 3]"
                ></b-form-select>
                <!-- ERROR MESSAGES -->
                <span
                  style="font-size: x-small"
                  class="text-danger font-weight-normal"
                  v-if="$v.newObjet.sensible.$dirty"
                >
                  {{
                    !$v.newObjet.sensible.required ? "champ obligatoire" : ""
                  }}
                </span>
                <!--  -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <b-tabs class="tab-solid tab-solid-primary mt-3">
        <b-tab active title="attributs">
          <AttributTable :attributs="OBJET.attributs" /> </b-tab
      ></b-tabs>
    </b-card>
  </div>
</template>

<script>
import ModalActions from "@/components/custom/ModalActions.vue";
import { mapGetters } from "vuex";
import { required, minLength } from "vuelidate/lib/validators"; //validation
import Swal from "sweetalert2";
import AttributTable from "../components/AttributTable";
import { maxBy } from "lodash";
export default {
  components: {
    ModalActions,
    AttributTable,
  },
  data: () => ({
    editMode: false,
    submitingForm: false,
    newObjet: {
      name: "",
      description: "",
      dcp: "",
      sensible: 0,
      concept: "",
    },
  }),
  validations: {
    newObjet: {
      name: {
        required,
        minLength: minLength(5),
      },
      dcp: {
        required,
      },
      sensible: {
        required,
      },
      concept: {
        required,
      },
    },
  },
  created() {
    this.$store.dispatch("concept/fetchAllConcepts");
    this.$store.dispatch("objet/fetchObjet", this.$route.params.id).then(() => {
      this.$store.dispatch("loader/toggleLoading", false);
    });
  },
  methods: {
    maxBy,
    updateItem() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        (this.submitingForm = true),
          this.$store
            .dispatch("objet/updateObjet", {
              id: this.$route.params.id,
              concept: this.newObjet.concept,
              dcp: this.newObjet.dcp,
              description: this.newObjet.description,
              name: this.newObjet.name,
              sensible: this.newObjet.sensible,
            })
            .then(() => {
              this.$store
                .dispatch("objet/fetchObjet", this.$route.params.id)
                .then(() => {
                  Swal.fire("L'objet est mis à jour !", "", "success");
                  this.editMode = false;
                  this.submitingForm = false;
                });
            });
      }
    },
  },
  computed: {
    ...mapGetters("objet", ["OBJET"]),
    ...mapGetters("concept", ["CONCEPTS"]),
    ...mapGetters(["isAdmin", "isSuper", "isArchitect"]),
    conceptsList() {
      return this.CONCEPTS.map((concept) => ({
        text: concept.name,
        value: concept["@id"],
      }));
    },
  },
  watch: {
    OBJET() {
      this.newObjet = {
        ...this.OBJET,
        concept: this.OBJET.concept["@id"],
      };
      this.newObjet.dcp =
        !this.OBJET.attributs ||
        !this.OBJET.attributs.length ||
        this.OBJET.attributs.every((attribut) => attribut.dcp === "Non")
          ? "Non"
          : "Oui";

      this.newObjet.sensible =
        !this.OBJET.attributs ||
        !this.OBJET.attributs.length ||
        this.OBJET.attributs.every((attribut) => attribut.sensible == 0)
          ? 0
          : maxBy(this.OBJET.attributs, (attribut) => attribut.sensible)
              .sensible;
    },
  },
};
</script>

<style></style>
